export default {
  'ABW': {
    name: {
      'ru-RU': 'Аруба',
      'en-US': 'Aruba'
    },
    flagPic: 'ABW.png',
    currencyCode: 'AWG'
  },
  'AFG': {
    name: {
      'ru-RU': 'Афганистан',
      'en-US': 'Afghanistan'
    },
    flagPic: 'AFG.png',
    currencyCode: 'AFN'
  },
  'AGO': {
    name: {
      'ru-RU': 'Ангола',
      'en-US': 'Angola'
    },
    flagPic: 'AGO.png',
    currencyCode: 'AOA'
  },
  'AIA': {
    name: {
      'ru-RU': 'Ангилья',
      'en-US': 'Anguilla'
    },
    flagPic: 'AIA.png',
    currencyCode: 'XCD'
  },
  'ALA': {
    name: {
      'ru-RU': 'Аландские острова',
      'en-US': 'Aland Islands'
    },
    flagPic: 'ALA.png',
    currencyCode: 'EUR'
  },
  'ALB': {
    name: {
      'ru-RU': 'Албания',
      'en-US': 'Albania'
    },
    flagPic: 'ALB.png',
    currencyCode: 'ALL'
  },
  'AND': {
    name: {
      'ru-RU': 'Андорра',
      'en-US': 'Andorra'
    },
    flagPic: 'AND.png',
    currencyCode: 'EUR'
  },
  'ARE': {
    name: {
      'ru-RU': 'ОАЭ',
      'en-US': 'United Arab Emirates'
    },
    flagPic: 'ARE.png',
    currencyCode: 'AED'
  },
  'ARG': {
    name: {
      'ru-RU': 'Аргентина',
      'en-US': 'Argentina'
    },
    flagPic: 'ARG.png',
    currencyCode: 'ARS'
  },
  'ARM': {
    name: {
      'ru-RU': 'Армения',
      'en-US': 'Armenia'
    },
    flagPic: 'ARM.png',
    currencyCode: 'AMD'
  },
  'ASM': {
    name: {
      'ru-RU': 'Американское Самоа',
      'en-US': 'American Samoa'
    },
    flagPic: 'ASM.png',
    currencyCode: 'USD'
  },
  'ATF': {
    name: {
      'ru-RU': 'Французские Южные и Антарктические Территории',
      'en-US': 'French Southern Territories'
    },
    flagPic: 'ATF.png',
    currencyCode: 'EUR'
  },
  'ATG': {
    name: {
      'ru-RU': 'Антигуа и Барбуда',
      'en-US': 'Antigua and Barbuda'
    },
    flagPic: 'ATG.png',
    currencyCode: 'XCD'
  },
  'AUS': {
    name: {
      'ru-RU': 'Австралия',
      'en-US': 'Australia'
    },
    flagPic: 'AUS.png',
    currencyCode: 'AUD'
  },
  'AUT': {
    name: {
      'ru-RU': 'Австрия',
      'en-US': 'Austria'
    },
    flagPic: 'AUT.png',
    currencyCode: 'EUR'
  },
  'AZE': {
    name: {
      'ru-RU': 'Азербайджан',
      'en-US': 'Azerbaijan'
    },
    flagPic: 'AZE.png',
    currencyCode: 'AZN'
  },
  'BDI': {
    name: {
      'ru-RU': 'Бурунди',
      'en-US': 'Burundi'
    },
    flagPic: 'BDI.png',
    currencyCode: 'BIF'
  },
  'BEL': {
    name: {
      'ru-RU': 'Бельгия',
      'en-US': 'Belgium'
    },
    flagPic: 'BEL.png',
    currencyCode: 'EUR'
  },
  'BEN': {
    name: {
      'ru-RU': 'Бенин',
      'en-US': 'Benin'
    },
    flagPic: 'BEN.png',
    currencyCode: 'XOF'
  },
  'BES': {
    name: {
      'ru-RU': 'Бонэйр, Синт-Эстатиус и Саба',
      'en-US': 'Bonaire, Sint Eustatius and Saba'
    },
    flagPic: 'BES.png',
    currencyCode: 'USD'
  },
  'BFA': {
    name: {
      'ru-RU': 'Буркина-Фасо',
      'en-US': 'Burkina Faso'
    },
    flagPic: 'BFA.png',
    currencyCode: 'XOF'
  },
  'BGD': {
    name: {
      'ru-RU': 'Бангладеш',
      'en-US': 'Bangladesh'
    },
    flagPic: 'BGD.png',
    currencyCode: 'BDT'
  },
  'BGR': {
    name: {
      'ru-RU': 'Болгария',
      'en-US': 'Bulgaria'
    },
    flagPic: 'BGR.png',
    currencyCode: 'BGN'
  },
  'BHR': {
    name: {
      'ru-RU': 'Бахрейн',
      'en-US': 'Bahrain'
    },
    flagPic: 'BHR.png',
    currencyCode: 'BHD'
  },
  'BHS': {
    name: {
      'ru-RU': 'Багамские острова',
      'en-US': 'Bahamas'
    },
    flagPic: 'BHS.png',
    currencyCode: 'BSD'
  },
  'BIH': {
    name: {
      'ru-RU': 'Босния и Герцеговина',
      'en-US': 'Bosnia and Herzegovina'
    },
    flagPic: 'BIH.png',
    currencyCode: 'BAM'
  },
  'BLM': {
    name: {
      'ru-RU': 'Сен-Бартелеми',
      'en-US': 'Saint Barthélemy'
    },
    flagPic: 'BLM.png',
    currencyCode: 'EUR'
  },
  'BLR': {
    name: {
      'ru-RU': 'Белоруссия',
      'en-US': 'Belarus'
    },
    flagPic: 'BLR.png',
    currencyCode: 'BYN'
  },
  'BLZ': {
    name: {
      'ru-RU': 'Белиз',
      'en-US': 'Belize'
    },
    flagPic: 'BLZ.png',
    currencyCode: 'BZD'
  },
  'BMU': {
    name: {
      'ru-RU': 'Бермуды',
      'en-US': 'Bermuda'
    },
    flagPic: 'BMU.png',
    currencyCode: 'BMD'
  },
  'BOL': {
    name: {
      'ru-RU': 'Боливия',
      'en-US': 'Bolivia'
    },
    flagPic: 'BOL.png',
    currencyCode: 'BOB'
  },
  'BRA': {
    name: {
      'ru-RU': 'Бразилия',
      'en-US': 'Brazil'
    },
    flagPic: 'BRA.png',
    currencyCode: 'BRL'
  },
  'BRB': {
    name: {
      'ru-RU': 'Барбадос',
      'en-US': 'Barbados'
    },
    flagPic: 'BRB.png',
    currencyCode: 'BBD'
  },
  'BRN': {
    name: {
      'ru-RU': 'Бруней',
      'en-US': 'Brunei Darussalam'
    },
    flagPic: 'BRN.png',
    currencyCode: 'BND'
  },
  'BTN': {
    name: {
      'ru-RU': 'Бутан',
      'en-US': 'Bhutan'
    },
    flagPic: 'BTN.png',
    currencyCode: 'BTN'
  },
  'BWA': {
    name: {
      'ru-RU': 'Ботсвана',
      'en-US': 'Botswana'
    },
    flagPic: 'BWA.png',
    currencyCode: 'BWP'
  },
  'CAF': {
    name: {
      'ru-RU': 'ЦАР',
      'en-US': 'Central African Republic'
    },
    flagPic: 'CAF.png',
    currencyCode: 'XAF'
  },
  'CAN': {
    name: {
      'ru-RU': 'Канада',
      'en-US': 'Canada'
    },
    flagPic: 'CAN.png',
    currencyCode: 'CAD'
  },
  'CCK': {
    name: {
      'ru-RU': 'Кокосовые острова',
      'en-US': 'Cocos (Keeling) Islands'
    },
    flagPic: 'CCK.png',
    currencyCode: 'AUD'
  },
  'CHE': {
    name: {
      'ru-RU': 'Швейцария',
      'en-US': 'Switzerland'
    },
    flagPic: 'CHE.png',
    currencyCode: 'CHF'
  },
  'CHL': {
    name: {
      'ru-RU': 'Чили',
      'en-US': 'Chile'
    },
    flagPic: 'CHL.png',
    currencyCode: 'CLP'
  },
  'CHN': {
    name: {
      'ru-RU': 'Китай',
      'en-US': 'China'
    },
    flagPic: 'CHN.png',
    currencyCode: 'CNY'
  },
  'CIV': {
    name: {
      'ru-RU': 'Кот-д’Ивуар',
      'en-US': 'Ivory Coast'
    },
    flagPic: 'CIV.png',
    currencyCode: 'CFA'
  },
  'CMR': {
    name: {
      'ru-RU': 'Камерун',
      'en-US': 'Cameroon'
    },
    flagPic: 'CMR.png',
    currencyCode: 'XAF'
  },
  'COD': {
    name: {
      'ru-RU': 'Демократическая Республика Конго',
      'en-US': 'Congo, Democratic Republic of the'
    },
    flagPic: 'COD.png',
    currencyCode: 'CDF'
  },
  'COG': {
    name: {
      'ru-RU': 'Республика Конго',
      'en-US': 'Congo'
    },
    flagPic: 'COG.png',
    currencyCode: 'XAF'
  },
  'COK': {
    name: {
      'ru-RU': 'Острова Кука',
      'en-US': 'Cook Islands'
    },
    flagPic: 'COK.png',
    currencyCode: 'CKD'
  },
  'COL': {
    name: {
      'ru-RU': 'Колумбия',
      'en-US': 'Colombia'
    },
    flagPic: 'COL.png',
    currencyCode: 'COP'
  },
  'COM': {
    name: {
      'ru-RU': 'Коморы',
      'en-US': 'Comoros'
    },
    flagPic: 'COM.png',
    currencyCode: 'KMF'
  },
  'CPV': {
    name: {
      'ru-RU': 'Кабо-Верде',
      'en-US': 'Cabo Verde'
    },
    flagPic: 'CPV.png',
    currencyCode: 'CVE'
  },
  'CRI': {
    name: {
      'ru-RU': 'Коста-Рика',
      'en-US': 'Costa Rica'
    },
    flagPic: 'CRI.png',
    currencyCode: 'CRC'
  },
  'CUB': {
    name: {
      'ru-RU': 'Куба',
      'en-US': 'Cuba'
    },
    flagPic: 'CUB.png',
    currencyCode: 'CUP'
  },
  'CUW': {
    name: {
      'ru-RU': 'Кюрасао',
      'en-US': 'Curaçao'
    },
    flagPic: 'CUW.png',
    currencyCode: 'ANG'
  },
  'CXR': {
    name: {
      'ru-RU': 'Остров Рождества',
      'en-US': 'Christmas Island'
    },
    flagPic: 'CXR.png',
    currencyCode: 'AUD'
  },
  'CYM': {
    name: {
      'ru-RU': 'Каймановы острова',
      'en-US': 'Cayman Islands'
    },
    flagPic: 'CYM.png',
    currencyCode: 'KYD'
  },
  'CYP': {
    name: {
      'ru-RU': 'Кипр',
      'en-US': 'Cyprus'
    },
    flagPic: 'CYP.png',
    currencyCode: 'EUR'
  },
  'CZE': {
    name: {
      'ru-RU': 'Чехия',
      'en-US': 'Czechia'
    },
    flagPic: 'CZE.png',
    currencyCode: 'CZK'
  },
  'DEU': {
    name: {
      'ru-RU': 'Германия',
      'en-US': 'Germany'
    },
    flagPic: 'DEU.png',
    currencyCode: 'EUR'
  },
  'DJI': {
    name: {
      'ru-RU': 'Джибути',
      'en-US': 'Djibouti'
    },
    flagPic: 'DJI.png',
    currencyCode: 'DJF'
  },
  'DMA': {
    name: {
      'ru-RU': 'Доминика',
      'en-US': 'Dominica'
    },
    flagPic: 'DMA.png',
    currencyCode: 'XCD'
  },
  'DNK': {
    name: {
      'ru-RU': 'Дания',
      'en-US': 'Denmark'
    },
    flagPic: 'DNK.png',
    currencyCode: 'DKK'
  },
  'DOM': {
    name: {
      'ru-RU': 'Доминиканская Республика',
      'en-US': 'Dominican Republic'
    },
    flagPic: 'DOM.png',
    currencyCode: 'DOP'
  },
  'DZA': {
    name: {
      'ru-RU': 'Алжир',
      'en-US': 'Algeria'
    },
    flagPic: 'DZA.png',
    currencyCode: 'DZD'
  },
  'ECU': {
    name: {
      'ru-RU': 'Эквадор',
      'en-US': 'Ecuador'
    },
    flagPic: 'ECU.png',
    currencyCode: 'USD'
  },
  'EGY': {
    name: {
      'ru-RU': 'Египет',
      'en-US': 'Egypt'
    },
    flagPic: 'EGY.png',
    currencyCode: 'EGP'
  },
  'ERI': {
    name: {
      'ru-RU': 'Эритрея',
      'en-US': 'Eritrea'
    },
    flagPic: 'ERI.png',
    currencyCode: 'ERN'
  },
  'ESH': {
    name: {
      'ru-RU': 'САДР',
      'en-US': 'Western Sahara'
    },
    flagPic: 'ESH.png',
    currencyCode: null
  },
  'ESP': {
    name: {
      'ru-RU': 'Испания',
      'en-US': 'Spain'
    },
    flagPic: 'ESP.png',
    currencyCode: 'EUR'
  },
  'EST': {
    name: {
      'ru-RU': 'Эстония',
      'en-US': 'Estonia'
    },
    flagPic: 'EST.png',
    currencyCode: 'EUR'
  },
  'ETH': {
    name: {
      'ru-RU': 'Эфиопия',
      'en-US': 'Ethiopia'
    },
    flagPic: 'ETH.png',
    currencyCode: 'ETB'
  },
  'FIN': {
    name: {
      'ru-RU': 'Финляндия',
      'en-US': 'Finland'
    },
    flagPic: 'FIN.png',
    currencyCode: 'EUR'
  },
  'FJI': {
    name: {
      'ru-RU': 'Фиджи',
      'en-US': 'Fiji'
    },
    flagPic: 'FJI.png',
    currencyCode: 'FJD'
  },
  'FLK': {
    name: {
      'ru-RU': 'Фолклендские острова',
      'en-US': 'Falkland Islands (Malvinas)'
    },
    flagPic: 'FLK.png',
    currencyCode: 'FKP'
  },
  'FRA': {
    name: {
      'ru-RU': 'Франция',
      'en-US': 'France'
    },
    flagPic: 'FRA.png',
    currencyCode: 'EUR'
  },
  'FRO': {
    name: {
      'ru-RU': 'Фарерские острова',
      'en-US': 'Faroe Islands'
    },
    flagPic: 'FRO.png',
    currencyCode: 'FOK'
  },
  'FSM': {
    name: {
      'ru-RU': 'Микронезия',
      'en-US': 'Micronesia'
    },
    flagPic: 'FSM.png',
    currencyCode: 'USD'
  },
  'GAB': {
    name: {
      'ru-RU': 'Габон',
      'en-US': 'Gabon'
    },
    flagPic: 'GAB.png',
    currencyCode: 'XAF'
  },
  'GBR': {
    name: {
      'ru-RU': 'Великобритания',
      'en-US': 'United Kingdom of Great Britain and Northern Ireland'
    },
    flagPic: 'GBR.png',
    currencyCode: 'GBP'
  },
  'GEO': {
    name: {
      'ru-RU': 'Грузия',
      'en-US': 'Georgia'
    },
    flagPic: 'GEO.png',
    currencyCode: 'GEL'
  },
  'GGY': {
    name: {
      'ru-RU': 'Гернси',
      'en-US': 'Guernsey'
    },
    flagPic: 'GGY.png',
    currencyCode: 'GGP'
  },
  'GHA': {
    name: {
      'ru-RU': 'Гана',
      'en-US': 'Ghana'
    },
    flagPic: 'GHA.png',
    currencyCode: 'GHS'
  },
  'GIB': {
    name: {
      'ru-RU': 'Гибралтар',
      'en-US': 'Gibraltar'
    },
    flagPic: 'GIB.png',
    currencyCode: 'GIP'
  },
  'GIN': {
    name: {
      'ru-RU': 'Гвинея',
      'en-US': 'Guinea'
    },
    flagPic: 'GIN.png',
    currencyCode: 'GNF'
  },
  'GMB': {
    name: {
      'ru-RU': 'Гамбия',
      'en-US': 'Gambia'
    },
    flagPic: 'GMB.png',
    currencyCode: 'GMD'
  },
  'GNB': {
    name: {
      'ru-RU': 'Гвинея-Бисау',
      'en-US': 'Guinea-Bissau'
    },
    flagPic: 'GNB.png',
    currencyCode: 'XOF'
  },
  'GNQ': {
    name: {
      'ru-RU': 'Экваториальная Гвинея',
      'en-US': 'Equatorial Guinea'
    },
    flagPic: 'GNQ.png',
    currencyCode: 'XAF'
  },
  'GRC': {
    name: {
      'ru-RU': 'Греция',
      'en-US': 'Greece'
    },
    flagPic: 'GRC.png',
    currencyCode: 'EUR'
  },
  'GRD': {
    name: {
      'ru-RU': 'Гренада',
      'en-US': 'Grenada'
    },
    flagPic: 'GRD.png',
    currencyCode: 'XCD'
  },
  'GTM': {
    name: {
      'ru-RU': 'Гватемала',
      'en-US': 'Guatemala'
    },
    flagPic: 'GTM.png',
    currencyCode: 'GTQ'
  },
  'GUF': {
    name: {
      'ru-RU': 'Гвиана',
      'en-US': 'French Guiana'
    },
    flagPic: 'GUF.png',
    currencyCode: 'EUR'
  },
  'GUM': {
    name: {
      'ru-RU': 'Гуам',
      'en-US': 'Guam'
    },
    flagPic: 'GUM.png',
    currencyCode: 'USD'
  },
  'GUY': {
    name: {
      'ru-RU': 'Гайана',
      'en-US': 'Guyana'
    },
    flagPic: 'GUY.png',
    currencyCode: 'GYD'
  },
  'HKG': {
    name: {
      'ru-RU': 'Гонконг',
      'en-US': 'Hong Kong'
    },
    flagPic: 'HKG.png',
    currencyCode: 'HKD'
  },
  'HND': {
    name: {
      'ru-RU': 'Гондурас',
      'en-US': 'Honduras'
    },
    flagPic: 'HND.png',
    currencyCode: 'HNL'
  },
  'HRV': {
    name: {
      'ru-RU': 'Хорватия',
      'en-US': 'Croatia'
    },
    flagPic: 'HRV.png',
    currencyCode: 'HRK'
  },
  'HTI': {
    name: {
      'ru-RU': 'Гаити',
      'en-US': 'Haiti'
    },
    flagPic: 'HTI.png',
    currencyCode: 'HTG'
  },
  'HUN': {
    name: {
      'ru-RU': 'Венгрия',
      'en-US': 'Hungary'
    },
    flagPic: 'HUN.png',
    currencyCode: 'HUF'
  },
  'IDN': {
    name: {
      'ru-RU': 'Индонезия',
      'en-US': 'Indonesia'
    },
    flagPic: 'IDN.png',
    currencyCode: 'IDR'
  },
  'IMN': {
    name: {
      'ru-RU': 'Остров Мэн',
      'en-US': 'Isle of Man'
    },
    flagPic: 'IMN.png',
    currencyCode: 'IMP'
  },
  'IND': {
    name: {
      'ru-RU': 'Индия',
      'en-US': 'India'
    },
    flagPic: 'IND.png',
    currencyCode: 'INR'
  },
  'IOT': {
    name: {
      'ru-RU': 'Британская территория в Индийском океане',
      'en-US': 'British Indian Ocean Territory'
    },
    flagPic: 'IOT.png',
    currencyCode: 'GBP'
  },
  'IRL': {
    name: {
      'ru-RU': 'Ирландия',
      'en-US': 'Ireland'
    },
    flagPic: 'IRL.png',
    currencyCode: 'EUR'
  },
  'IRN': {
    name: {
      'ru-RU': 'Иран',
      'en-US': 'Iran (Islamic Republic of)'
    },
    flagPic: 'IRN.png',
    currencyCode: 'IRR'
  },
  'IRQ': {
    name: {
      'ru-RU': 'Ирак',
      'en-US': 'Iraq'
    },
    flagPic: 'IRQ.png',
    currencyCode: 'IQD'
  },
  'ISL': {
    name: {
      'ru-RU': 'Исландия',
      'en-US': 'Iceland'
    },
    flagPic: 'ISL.png',
    currencyCode: 'ISK'
  },
  'ISR': {
    name: {
      'ru-RU': 'Израиль',
      'en-US': 'Israel'
    },
    flagPic: 'ISR.png',
    currencyCode: 'ILS'
  },
  'ITA': {
    name: {
      'ru-RU': 'Италия',
      'en-US': 'Italy'
    },
    flagPic: 'ITA.png',
    currencyCode: 'EUR'
  },
  'JAM': {
    name: {
      'ru-RU': 'Ямайка',
      'en-US': 'Jamaica'
    },
    flagPic: 'JAM.png',
    currencyCode: 'JMD'
  },
  'JEY': {
    name: {
      'ru-RU': 'Джерси',
      'en-US': 'Jersey'
    },
    flagPic: 'JEY.png',
    currencyCode: 'GBP'
  },
  'JOR': {
    name: {
      'ru-RU': 'Иордания',
      'en-US': 'Jordan'
    },
    flagPic: 'JOR.png',
    currencyCode: 'JOD'
  },
  'JPN': {
    name: {
      'ru-RU': 'Япония',
      'en-US': 'Japan'
    },
    flagPic: 'JPN.png',
    currencyCode: 'JPY'
  },
  'KAZ': {
    name: {
      'ru-RU': 'Казахстан',
      'en-US': 'Kazakhstan'
    },
    flagPic: 'KAZ.png',
    currencyCode: 'KZT'
  },
  'KEN': {
    name: {
      'ru-RU': 'Кения',
      'en-US': 'Kenya'
    },
    flagPic: 'KEN.png',
    currencyCode: 'KES'
  },
  'KGZ': {
    name: {
      'ru-RU': 'Киргизия',
      'en-US': 'Kyrgyzstan'
    },
    flagPic: 'KGZ.png',
    currencyCode: 'KGS'
  },
  'KHM': {
    name: {
      'ru-RU': 'Камбоджа',
      'en-US': 'Cambodia'
    },
    flagPic: 'KHM.png',
    currencyCode: 'KHR'
  },
  'KIR': {
    name: {
      'ru-RU': 'Кирибати',
      'en-US': 'Kiribati'
    },
    flagPic: 'KIR.png',
    currencyCode: 'KID'
  },
  'KNA': {
    name: {
      'ru-RU': 'Сент-Китс и Невис',
      'en-US': 'Saint Kitts and Nevis'
    },
    flagPic: 'KNA.png',
    currencyCode: 'XCD'
  },
  'KOR': {
    name: {
      'ru-RU': 'Республика Корея',
      'en-US': 'Korea, Republic of'
    },
    flagPic: 'KOR.png',
    currencyCode: 'KRW'
  },
  'KWT': {
    name: {
      'ru-RU': 'Кувейт',
      'en-US': 'Kuwait'
    },
    flagPic: 'KWT.png',
    currencyCode: 'KWD'
  },
  'LAO': {
    name: {
      'ru-RU': 'Лаос',
      'en-US': 'Lao People\'s Democratic Republic'
    },
    flagPic: 'LAO.png',
    currencyCode: 'LAK'
  },
  'LBN': {
    name: {
      'ru-RU': 'Ливан',
      'en-US': 'Lebanon'
    },
    flagPic: 'LBN.png',
    currencyCode: 'LBP'
  },
  'LBR': {
    name: {
      'ru-RU': 'Либерия',
      'en-US': 'Liberia'
    },
    flagPic: 'LBR.png',
    currencyCode: 'LRD'
  },
  'LBY': {
    name: {
      'ru-RU': 'Ливия',
      'en-US': 'Libya'
    },
    flagPic: 'LBY.png',
    currencyCode: 'LYD'
  },
  'LCA': {
    name: {
      'ru-RU': 'Сент-Люсия',
      'en-US': 'Saint Lucia'
    },
    flagPic: 'LCA.png',
    currencyCode: 'XCD'
  },
  'LIE': {
    name: {
      'ru-RU': 'Лихтенштейн',
      'en-US': 'Liechtenstein'
    },
    flagPic: 'LIE.png',
    currencyCode: 'CHF'
  },
  'LKA': {
    name: {
      'ru-RU': 'Шри-Ланка',
      'en-US': 'Sri Lanka'
    },
    flagPic: 'LKA.png',
    currencyCode: 'LKR'
  },
  'LSO': {
    name: {
      'ru-RU': 'Лесото',
      'en-US': 'Lesotho'
    },
    flagPic: 'LSO.png',
    currencyCode: 'LSL'
  },
  'LTU': {
    name: {
      'ru-RU': 'Литва',
      'en-US': 'Lithuania'
    },
    flagPic: 'LTU.png',
    currencyCode: 'EUR'
  },
  'LVA': {
    name: {
      'ru-RU': 'Латвия',
      'en-US': 'Latvia'
    },
    flagPic: 'LVA.png',
    currencyCode: 'EUR'
  },
  'LUX': {
    name: {
      'ru-RU': 'Люксембург',
      'en-US': 'Luxembourg'
    },
    flagPic: 'LUX.png',
    currencyCode: 'EUR'
  },
  'MAC': {
    name: {
      'ru-RU': 'Макао',
      'en-US': 'Macao'
    },
    flagPic: 'MAC.png',
    currencyCode: 'MOP'
  },
  'MAF': {
    name: {
      'ru-RU': 'Сен-Мартен',
      'en-US': 'Saint Martin (French part)'
    },
    flagPic: 'MAF.png',
    currencyCode: 'EUR'
  },
  'MAR': {
    name: {
      'ru-RU': 'Марокко',
      'en-US': 'Morocco'
    },
    flagPic: 'MAR.png',
    currencyCode: 'MAD'
  },
  'MCO': {
    name: {
      'ru-RU': 'Монако',
      'en-US': 'Monaco'
    },
    flagPic: 'MCO.png',
    currencyCode: 'EUR'
  },
  'MDA': {
    name: {
      'ru-RU': 'Молдавия',
      'en-US': 'Moldova'
    },
    flagPic: 'MDA.png',
    currencyCode: 'MDL'
  },
  'MDG': {
    name: {
      'ru-RU': 'Мадагаскар',
      'en-US': 'Madagascar'
    },
    flagPic: 'MDG.png',
    currencyCode: 'MGA'
  },
  'MDV': {
    name: {
      'ru-RU': 'Мальдивы',
      'en-US': 'Maldives'
    },
    flagPic: 'MDV.png',
    currencyCode: 'MVR'
  },
  'MEX': {
    name: {
      'ru-RU': 'Мексика',
      'en-US': 'Mexico'
    },
    flagPic: 'MEX.png',
    currencyCode: 'MXN'
  },
  'MHL': {
    name: {
      'ru-RU': 'Маршалловы Острова',
      'en-US': 'Marshall Islands'
    },
    flagPic: 'MHL.png',
    currencyCode: 'USD'
  },
  'MKD': {
    name: {
      'ru-RU': 'Северная Македония',
      'en-US': 'North Macedonia'
    },
    flagPic: 'MKD.png',
    currencyCode: 'MKD'
  },
  'MLI': {
    name: {
      'ru-RU': 'Мали',
      'en-US': 'Mali'
    },
    flagPic: 'MLI.png',
    currencyCode: 'XOF'
  },
  'MLT': {
    name: {
      'ru-RU': 'Мальта',
      'en-US': 'Malta'
    },
    flagPic: 'MLT.png',
    currencyCode: 'EUR'
  },
  'MMR': {
    name: {
      'ru-RU': 'Мьянма',
      'en-US': 'Myanmar'
    },
    flagPic: 'MMR.png',
    currencyCode: 'MMK'
  },
  'MNE': {
    name: {
      'ru-RU': 'Черногория',
      'en-US': 'Montenegro'
    },
    flagPic: 'MNE.png',
    currencyCode: 'EUR'
  },
  'MNG': {
    name: {
      'ru-RU': 'Монголия',
      'en-US': 'Mongolia'
    },
    flagPic: 'MNG.png',
    currencyCode: 'MNT'
  },
  'MNP': {
    name: {
      'ru-RU': 'Северные Марианские острова',
      'en-US': 'Northern Mariana Islands'
    },
    flagPic: 'MNP.png',
    currencyCode: 'USD'
  },
  'MOZ': {
    name: {
      'ru-RU': 'Мозамбик',
      'en-US': 'Mozambique'
    },
    flagPic: 'MOZ.png',
    currencyCode: 'MZN'
  },
  'MRT': {
    name: {
      'ru-RU': 'Мавритания',
      'en-US': 'Mauritania'
    },
    flagPic: 'MRT.png',
    currencyCode: 'MRU'
  },
  'MSR': {
    name: {
      'ru-RU': 'Монтсеррат',
      'en-US': 'Montserrat'
    },
    flagPic: 'MSR.png',
    currencyCode: 'XCD'
  },
  'MTQ': {
    name: {
      'ru-RU': 'Мартиника',
      'en-US': 'Martinique'
    },
    flagPic: 'MTQ.png',
    currencyCode: 'EUR'
  },
  'MUS': {
    name: {
      'ru-RU': 'Маврикий',
      'en-US': 'Mauritius'
    },
    flagPic: 'MUS.png',
    currencyCode: 'MUR'
  },
  'MWI': {
    name: {
      'ru-RU': 'Малави',
      'en-US': 'Malawi'
    },
    flagPic: 'MWI.png',
    currencyCode: 'MWK'
  },
  'MYS': {
    name: {
      'ru-RU': 'Малайзия',
      'en-US': 'Malaysia'
    },
    flagPic: 'MYS.png',
    currencyCode: 'MYR'
  },
  'MYT': {
    name: {
      'ru-RU': 'Майотта',
      'en-US': 'Mayotte'
    },
    flagPic: 'MYT.png',
    currencyCode: 'EUR'
  },
  'NAM': {
    name: {
      'ru-RU': 'Намибия',
      'en-US': 'Namibia'
    },
    flagPic: 'NAM.png',
    currencyCode: 'NAD'
  },
  'NCL': {
    name: {
      'ru-RU': 'Новая Каледония',
      'en-US': 'New Caledonia'
    },
    flagPic: 'NCL.png',
    currencyCode: 'XPF'
  },
  'NER': {
    name: {
      'ru-RU': 'Нигер',
      'en-US': 'Niger'
    },
    flagPic: 'NER.png',
    currencyCode: 'XOF'
  },
  'NFK': {
    name: {
      'ru-RU': 'Остров Норфолк',
      'en-US': 'Norfolk Island'
    },
    flagPic: 'NFK.png',
    currencyCode: 'AUD'
  },
  'NGA': {
    name: {
      'ru-RU': 'Нигерия',
      'en-US': 'Nigeria'
    },
    flagPic: 'NGA.png',
    currencyCode: 'NGN'
  },
  'NIC': {
    name: {
      'ru-RU': 'Никарагуа',
      'en-US': 'Nicaragua'
    },
    flagPic: 'NIC.png',
    currencyCode: 'NIO'
  },
  'NIU': {
    name: {
      'ru-RU': 'Ниуэ',
      'en-US': 'Niue'
    },
    flagPic: 'NIU.png',
    currencyCode: 'NZD'
  },
  'NLD': {
    name: {
      'ru-RU': 'Нидерланды',
      'en-US': 'Netherlands'
    },
    flagPic: 'NLD.png',
    currencyCode: 'EUR'
  },
  'NOR': {
    name: {
      'ru-RU': 'Норвегия',
      'en-US': 'Norway'
    },
    flagPic: 'NOR.png',
    currencyCode: 'NOK'
  },
  'NPL': {
    name: {
      'ru-RU': 'Непал',
      'en-US': 'Nepal'
    },
    flagPic: 'NPL.png',
    currencyCode: 'NPR'
  },
  'NRU': {
    name: {
      'ru-RU': 'Науру',
      'en-US': 'Nauru'
    },
    flagPic: 'NRU.png',
    currencyCode: 'AUD'
  },
  'NZL': {
    name: {
      'ru-RU': 'Новая Зеландия',
      'en-US': 'New Zealand'
    },
    flagPic: 'NZL.png',
    currencyCode: 'NZD'
  },
  'OMN': {
    name: {
      'ru-RU': 'Оман',
      'en-US': 'Oman'
    },
    flagPic: 'OMN.png',
    currencyCode: 'OMR'
  },
  'PAK': {
    name: {
      'ru-RU': 'Пакистан',
      'en-US': 'Pakistan'
    },
    flagPic: 'PAK.png',
    currencyCode: 'PKR'
  },
  'PAN': {
    name: {
      'ru-RU': 'Панама',
      'en-US': 'Panama'
    },
    flagPic: 'PAN.png',
    currencyCode: 'PAB'
  },
  'PCN': {
    name: {
      'ru-RU': 'Острова Питкэрн',
      'en-US': 'Pitcairn'
    },
    flagPic: 'PCN.png',
    currencyCode: 'NZD'
  },
  'PER': {
    name: {
      'ru-RU': 'Перу',
      'en-US': 'Peru'
    },
    flagPic: 'PER.png',
    currencyCode: 'PEN'
  },
  'PHL': {
    name: {
      'ru-RU': 'Филиппины',
      'en-US': 'Philippines'
    },
    flagPic: 'PHL.png',
    currencyCode: 'PHP'
  },
  'PLW': {
    name: {
      'ru-RU': 'Палау',
      'en-US': 'Palau'
    },
    flagPic: 'PLW.png',
    currencyCode: 'USD'
  },
  'PNG': {
    name: {
      'ru-RU': 'Папуа — Новая Гвинея',
      'en-US': 'Papua New Guinea'
    },
    flagPic: 'PNG.png',
    currencyCode: 'PGK'
  },
  'POL': {
    name: {
      'ru-RU': 'Польша',
      'en-US': 'Poland'
    },
    flagPic: 'POL.png',
    currencyCode: 'PLN'
  },
  'PRI': {
    name: {
      'ru-RU': 'Пуэрто-Рико',
      'en-US': 'Puerto Rico'
    },
    flagPic: 'PRI.png',
    currencyCode: 'USD'
  },
  'PRK': {
    name: {
      'ru-RU': 'КНДР',
      'en-US': 'DPRK'
    },
    flagPic: 'PRK.png',
    currencyCode: 'KPW'
  },
  'PRT': {
    name: {
      'ru-RU': 'Португалия',
      'en-US': 'Portugal'
    },
    flagPic: 'PRT.png',
    currencyCode: 'EUR'
  },
  'PRY': {
    name: {
      'ru-RU': 'Парагвай',
      'en-US': 'Paraguay'
    },
    flagPic: 'PRY.png',
    currencyCode: 'PYG'
  },
  'PSE': {
    name: {
      'ru-RU': 'Палестина',
      'en-US': 'Palestine'
    },
    flagPic: 'PSE.png',
    currencyCode: 'ILS'
  },
  'PYF': {
    name: {
      'ru-RU': 'Французская Полинезия',
      'en-US': 'French Polynesia'
    },
    flagPic: 'PYF.png',
    currencyCode: 'XPF'
  },
  'QAT': {
    name: {
      'ru-RU': 'Катар',
      'en-US': 'Qatar'
    },
    flagPic: 'QAT.png',
    currencyCode: 'QAR'
  },
  'REU': {
    name: {
      'ru-RU': 'Реюньон',
      'en-US': 'Réunion'
    },
    flagPic: 'REU.png',
    currencyCode: 'EUR'
  },
  'ROU': {
    name: {
      'ru-RU': 'Румыния',
      'en-US': 'Romania'
    },
    flagPic: 'ROU.png',
    currencyCode: 'RON'
  },
  'RUS': {
    name: {
      'ru-RU': 'Россия',
      'en-US': 'Russia'
    },
    flagPic: 'RUS.png',
    currencyCode: 'RUB'
  },
  'RWA': {
    name: {
      'ru-RU': 'Руанда',
      'en-US': 'Rwanda'
    },
    flagPic: 'RWA.png',
    currencyCode: 'RWF'
  },
  'SAU': {
    name: {
      'ru-RU': 'Саудовская Аравия',
      'en-US': 'Saudi Arabia'
    },
    flagPic: 'SAU.png',
    currencyCode: 'SAR'
  },
  'SDN': {
    name: {
      'ru-RU': 'Судан',
      'en-US': 'Sudan'
    },
    flagPic: 'SDN.png',
    currencyCode: 'SDG'
  },
  'SEN': {
    name: {
      'ru-RU': 'Сенегал',
      'en-US': 'Senegal'
    },
    flagPic: 'SEN.png',
    currencyCode: 'XOF'
  },
  'SGP': {
    name: {
      'ru-RU': 'Сингапур',
      'en-US': 'Singapore'
    },
    flagPic: 'SGP.png',
    currencyCode: 'SGD'
  },
  'SGS': {
    name: {
      'ru-RU': 'Южная Георгия и Южные Сандвичевы острова',
      'en-US': 'South Georgia and the South Sandwich Islands'
    },
    flagPic: 'SGS.png',
    currencyCode: 'GBP'
  },
  'SHN': {
    name: {
      'ru-RU': 'Острова Святой Елены, Вознесения и Тристан-да-Кунья',
      'en-US': 'Saint Helena, Ascension and Tristan da Cunha'
    },
    flagPic: 'SHN.png',
    currencyCode: 'SHP'
  },
  'SLB': {
    name: {
      'ru-RU': 'Соломоновы Острова',
      'en-US': 'Solomon Islands'
    },
    flagPic: 'SLB.png',
    currencyCode: 'SBD'
  },
  'SLE': {
    name: {
      'ru-RU': 'Сьерра-Леоне',
      'en-US': 'Sierra Leone'
    },
    flagPic: 'SLE.png',
    currencyCode: 'SLL'
  },
  'SLV': {
    name: {
      'ru-RU': 'Сальвадор',
      'en-US': 'El Salvador'
    },
    flagPic: 'SLV.png',
    currencyCode: 'USD'
  },
  'SMR': {
    name: {
      'ru-RU': 'Сан-Марино',
      'en-US': 'San Marino'
    },
    flagPic: 'SMR.png',
    currencyCode: 'EUR'
  },
  'SOM': {
    name: {
      'ru-RU': 'Сомали',
      'en-US': 'Somalia'
    },
    flagPic: 'SOM.png',
    currencyCode: 'SOS'
  },
  'SPM': {
    name: {
      'ru-RU': 'Сен-Пьер и Микелон',
      'en-US': 'Saint Pierre and Miquelon'
    },
    flagPic: 'SPM.png',
    currencyCode: 'EUR'
  },
  'SRB': {
    name: {
      'ru-RU': 'Сербия',
      'en-US': 'Serbia'
    },
    flagPic: 'SRB.png',
    currencyCode: 'RSD'
  },
  'SSD': {
    name: {
      'ru-RU': 'Южный Судан',
      'en-US': 'South Sudan'
    },
    flagPic: 'SSD.png',
    currencyCode: 'SSP'
  },
  'STP': {
    name: {
      'ru-RU': 'Сан-Томе и Принсипи',
      'en-US': 'Sao Tome and Principe'
    },
    flagPic: 'STP.png',
    currencyCode: 'STN'
  },
  'SUR': {
    name: {
      'ru-RU': 'Суринам',
      'en-US': 'Suriname'
    },
    flagPic: 'SUR.png',
    currencyCode: 'SRD'
  },
  'SVK': {
    name: {
      'ru-RU': 'Словакия',
      'en-US': 'Slovakia'
    },
    flagPic: 'SVK.png',
    currencyCode: 'EUR'
  },
  'SVN': {
    name: {
      'ru-RU': 'Словения',
      'en-US': 'Slovenia'
    },
    flagPic: 'SVN.png',
    currencyCode: 'EUR'
  },
  'SWE': {
    name: {
      'ru-RU': 'Швеция',
      'en-US': 'Sweden'
    },
    flagPic: 'SWE.png',
    currencyCode: 'SEK'
  },
  'SWZ': {
    name: {
      'ru-RU': 'Эсватини',
      'en-US': 'Eswatini'
    },
    flagPic: 'SWZ.png',
    currencyCode: 'SZL'
  },
  'SXM': {
    name: {
      'ru-RU': 'Синт-Мартен',
      'en-US': 'Sint Maarten (Dutch part)'
    },
    flagPic: 'SXM.png',
    currencyCode: 'ANG'
  },
  'SYC': {
    name: {
      'ru-RU': 'Сейшельские Острова',
      'en-US': 'Seychelles'
    },
    flagPic: 'SYC.png',
    currencyCode: 'SCR'
  },
  'SYR': {
    name: {
      'ru-RU': 'Сирия',
      'en-US': 'Syrian Arab Republic'
    },
    flagPic: 'SYR.png',
    currencyCode: 'SYP'
  },
  'TCA': {
    name: {
      'ru-RU': 'Тёркс и Кайкос',
      'en-US': 'Turks and Caicos Islands'
    },
    flagPic: 'TCA.png',
    currencyCode: 'USD'
  },
  'TCD': {
    name: {
      'ru-RU': 'Чад',
      'en-US': 'Chad'
    },
    flagPic: 'TCD.png',
    currencyCode: 'XAF'
  },
  'TGO': {
    name: {
      'ru-RU': 'Того',
      'en-US': 'Togo'
    },
    flagPic: 'TGO.png',
    currencyCode: 'XOF'
  },
  'THA': {
    name: {
      'ru-RU': 'Таиланд',
      'en-US': 'Thailand'
    },
    flagPic: 'THA.png',
    currencyCode: 'THB'
  },
  'TJK': {
    name: {
      'ru-RU': 'Таджикистан',
      'en-US': 'Tajikistan'
    },
    flagPic: 'TJK.png',
    currencyCode: 'TJS'
  },
  'TKL': {
    name: {
      'ru-RU': 'Токелау',
      'en-US': 'Tokelau'
    },
    flagPic: 'TKL.png',
    currencyCode: 'NZD'
  },
  'TKM': {
    name: {
      'ru-RU': 'Туркмения',
      'en-US': 'Turkmenistan'
    },
    flagPic: 'TKM.png',
    currencyCode: 'TMT'
  },
  'TLS': {
    name: {
      'ru-RU': 'Восточный Тимор',
      'en-US': 'East Timor'
    },
    flagPic: 'TLS.png',
    currencyCode: 'USD'
  },
  'TON': {
    name: {
      'ru-RU': 'Тонга',
      'en-US': 'Tonga'
    },
    flagPic: 'TON.png',
    currencyCode: 'TOP'
  },
  'TTO': {
    name: {
      'ru-RU': 'Тринидад и Тобаго',
      'en-US': 'Trinidad and Tobago'
    },
    flagPic: 'TTO.png',
    currencyCode: 'TTD'
  },
  'TUN': {
    name: {
      'ru-RU': 'Тунис',
      'en-US': 'Tunisia'
    },
    flagPic: 'TUN.png',
    currencyCode: 'TND'
  },
  'TUR': {
    name: {
      'ru-RU': 'Турция',
      'en-US': 'Turkey'
    },
    flagPic: 'TUR.png',
    currencyCode: 'TRY'
  },
  'TUV': {
    name: {
      'ru-RU': 'Тувалу',
      'en-US': 'Tuvalu'
    },
    flagPic: 'TUV.png',
    currencyCode: 'AUD'
  },
  'TWN': {
    name: {
      'ru-RU': 'Тайвань',
      'en-US': 'Taiwan'
    },
    flagPic: 'TWN.png',
    currencyCode: 'TWD'
  },
  'TZA': {
    name: {
      'ru-RU': 'Танзания',
      'en-US': 'Tanzania, United Republic of'
    },
    flagPic: 'TZA.png',
    currencyCode: 'TZS'
  },
  'UGA': {
    name: {
      'ru-RU': 'Уганда',
      'en-US': 'Uganda'
    },
    flagPic: 'UGA.png',
    currencyCode: 'UGX'
  },
  'UKR': {
    name: {
      'ru-RU': 'Украина',
      'en-US': 'Ukraine'
    },
    flagPic: 'UKR.png',
    currencyCode: 'UAH'
  },
  'URY': {
    name: {
      'ru-RU': 'Уругвай',
      'en-US': 'Uruguay'
    },
    flagPic: 'URY.png',
    currencyCode: 'UYU'
  },
  'USA': {
    name: {
      'ru-RU': 'США',
      'en-US': 'USA'
    },
    flagPic: 'USA.png',
    currencyCode: 'USD'
  },
  'UZB': {
    name: {
      'ru-RU': 'Узбекистан',
      'en-US': 'Uzbekistan'
    },
    flagPic: 'UZB.png',
    currencyCode: 'UZS'
  },
  'VGB': {
    name: {
      'ru-RU': 'Британские Виргинские острова',
      'en-US': 'British Virgin Islands'
    },
    flagPic: 'VGB.png',
    currencyCode: 'USD'
  },
  'VAT': {
    name: {
      'ru-RU': 'Ватикан',
      'en-US': 'Vatican'
    },
    flagPic: 'VAT.png',
    currencyCode: 'EUR'
  },
  'VCT': {
    name: {
      'ru-RU': 'Сент-Винсент и Гренадины',
      'en-US': 'Saint Vincent and the Grenadines'
    },
    flagPic: 'VCT.png',
    currencyCode: 'XCD'
  },
  'VEN': {
    name: {
      'ru-RU': 'Венесуэла',
      'en-US': 'Venezuela'
    },
    flagPic: 'VEN.png',
    currencyCode: 'VEF'
  },
  'VIR': {
    name: {
      'ru-RU': 'Американские Виргинские Острова',
      'en-US': 'Virgin Islands of the United States'
    },
    flagPic: 'VIR.png',
    currencyCode: 'USD'
  },
  'VNM': {
    name: {
      'ru-RU': 'Вьетнам',
      'en-US': 'Viet Nam'
    },
    flagPic: 'VNM.png',
    currencyCode: 'VND'
  },
  'VUT': {
    name: {
      'ru-RU': 'Вануату',
      'en-US': 'Vanuatu'
    },
    flagPic: 'VUT.png',
    currencyCode: 'VUV'
  },
  'WLF': {
    name: {
      'ru-RU': 'Уоллис и Футуна',
      'en-US': 'Wallis and Futuna'
    },
    flagPic: 'WLF.png',
    currencyCode: 'XPF'
  },
  'WSM': {
    name: {
      'ru-RU': 'Самоа',
      'en-US': 'Samoa'
    },
    flagPic: 'WSM.png',
    currencyCode: 'WST'
  },
  'YEM': {
    name: {
      'ru-RU': 'Йемен',
      'en-US': 'Yemen'
    },
    flagPic: 'YEM.png',
    currencyCode: 'YER'
  },
  'ZAF': {
    name: {
      'ru-RU': 'ЮАР',
      'en-US': 'South Africa'
    },
    flagPic: 'ZAF.png',
    currencyCode: 'ZAR'
  },
  'ZMB': {
    name: {
      'ru-RU': 'Замбия',
      'en-US': 'Zambia'
    },
    flagPic: 'ZMB.png',
    currencyCode: 'ZMW'
  },
  'ZWE': {
    name: {
      'ru-RU': 'Зимбабве',
      'en-US': 'Zimbabwe'
    },
    flagPic: 'ZWE.png',
    currencyCode: 'USD'
  }
}