export default {
  'white': 'app-card-white-color',
  'gray': 'app-card-grey-color',
  'red': 'app-card-red-color',
  'orange': 'app-card-orange-color',
  'yellow': 'app-card-yellow-color',
  'lime': 'app-card-lime-color',
  'green': 'app-card-green-color',
  'lightblue': 'app-card-lightblue-color',
  'blue': 'app-card-blue-color',
  'violet': 'app-card-violet-color',
  'fuchsia': 'app-card-fuchsia-color',
  'pink': 'app-card-pink-color'
}